import axios from "axios";
import crypto from "crypto";
import { Message, MessageBox } from "element-ui";

//本地导入
import { ObjectEx } from "@/utils";
import { getLocale } from "@/lang";
import { getUid } from "@/utils/cookies";
import { CfgAnalyze } from "@/utils/cfg-analyze";
import { UserModule } from "@/store/modules/user";

//消息头
export const getHeaders = () => {
  //定义变量
  const headers: any = {};

  //token存在
  if (UserModule.token) {
    //语言
    headers["X-LANG"] = getLocale();

    //随机数
    headers["X-NONCE"] = "justanonce";

    //用户ID
    const uid = UserModule.uid ? UserModule.uid : getUid();
    headers["X-UID"] = uid;

    //时间戳
    headers["X-TS"] = Date.parse(new Date().toString()) / 1000;

    //消息签名
    const md5 = crypto.createHash("md5");
    md5.update(`${headers["X-NONCE"]}${headers["X-TS"]}${headers["X-UID"]}${UserModule.token}`);
    headers["X-SIGN"] = md5.digest("hex").toUpperCase();
  }

  //返回消息头
  return headers;
};
//消息头
export const getFileHeaders = () => {
  //定义变量
  const headers: any = {};

  //token存在
  if (UserModule.token) {
    //语言
    headers["X-LANG"] = getLocale();

    //随机数
    headers["X-NONCE"] = "justanonce";

    //类型
    headers["Content-Type"] = "multipart/form-data";

    //用户ID
    const uid = UserModule.uid ? UserModule.uid : getUid();
    headers["X-UID"] = uid;

    //时间戳
    headers["X-TS"] = Date.parse(new Date().toString()) / 1000;

    //消息签名
    const md5 = crypto.createHash("md5");
    md5.update(`${headers["X-NONCE"]}${headers["X-TS"]}${headers["X-UID"]}${UserModule.token}`);
    headers["X-SIGN"] = md5.digest("hex").toUpperCase();
  }

  //返回消息头
  return headers;
};

//获取API
export const getAppBaseApi = () => {
  //获取API
  const baseApi: string = CfgAnalyze.getBaseApi();

  //判断返回
  if (baseApi && baseApi.trim().length > 0) {
    return baseApi;
  }

  //返回空
  return "";
};

//创建服务
const service = axios.create({
  timeout: 60000,
  baseURL: getAppBaseApi(),
});

//请求拦截器
service.interceptors.request.use(
  //接口请求
  (config) => {
    //添加消息头
    const headers = getHeaders();
    config.headers = ObjectEx.merge(config.headers, headers);

    //返回配置
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//响应拦截器
service.interceptors.response.use(
  //接口回调
  (response) => {
    //数据赋值
    const res = response.data;

    //异常状态
    if (res.status !== 0) {
      //Token过期
      if (res.status === 50008 || res.status === 50012 || res.status === 50014 || res.status === 401) {
        //设置Token过期
        UserModule.setIsOverTime();

        //显示提示
        MessageBox.confirm("你已被登出，可以取消继续留在该页面，或者重新登录", "确定登出", {
          type: "warning",
          cancelButtonText: "取消",
          confirmButtonText: "重新登录",
        }).then(() => {
          //充值Token
          UserModule.resetToken();

          //重新加载
          location.reload();
        });
      }
      //权限不足
      else if (res.status === 402) {
        //显示提示
        Message({
          type: "error",
          duration: 5 * 1000,
          message: "您的权限不足，请与管理员联系",
        });
      }
      //账号已存在
      else if (res.status === 300002) {
        //显示提示
        Message({
          type: "error",
          duration: 5 * 1000,
          message: "会员账号已存在",
        });
      }
      //其他错误
      else {
        Message({
          type: "error",
          duration: 5 * 1000,
          message: res.msg || "Error",
        });
      }

      //返回错误
      return Promise.reject(new Error(res.msg || "Error"));
    }
    //正常请求
    else {
      //返回结果
      return res;
    }
  },
  (error) => {
    //错误消息
    Message({
      type: "error",
      duration: 5 * 1000,
      message: error.message,
    });

    //返回错误
    return Promise.reject(error);
  }
);

//导出
export default service;

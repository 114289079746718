
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { ryPushTplList, ryPushTplRemove, ryPushTplSave, ryPushTplType } from "@/api/request/system";

//组件
@Component({
  name: "RyPush",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private typeList: any[] = [];
  private subTypeList: any = [];
  private listLoading: boolean = true;

  //请求参数
  private listQuery = {
    //页码数据
    page: 1,
    page_size: 20,

    //临时数据
    id_str: "",

    //请求数据
    id: 0, //模板ID
    type: 0, //类型
    lang: "zh", //语言
    sub_type: 0, //子类型
    title: "", //标题
  };

  //语言列表
  private langList: any[] = [
    { lang: "", name: "全部语言" },
    { lang: "zh", name: "中文" },
    { lang: "en", name: "英文" },
    { lang: "id", name: "印尼语" },
  ];

  //创建
  created() {
    //获取类型列表
    this.getTypeList();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.id = Number(this.listQuery.id_str);

    //获取数据
    const { data } = await ryPushTplList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取类型列表
  private async getTypeList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await ryPushTplType(this.listQuery);

    //数据赋值
    this.typeList = data;
    this.typeList.unshift({ id: 0, name: "请选择类型", list: [] });
    for (var i: number = 0; i < this.typeList.length; i++) {
      this.typeList[i].list.unshift({ id: 0, name: "请选择子类型" });
    }
    this.assSubTypeList();
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.addForm = {
      //临时数据
      sort_str: "",

      //请求数据
      id: 0, //ID
      sort: 0, //排序
      lang: "", //归属语言，不可编辑
      title: "", //标题
      type: 0, //类型ID
      sub_type: 0, //子类型
      content: "", //消息内容
    };
    this.assFormSubTypeList();

    //显示对话框
    this.dialogVisible = true;
    this.dialogStatus = "create";
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //子类型
  private assSubTypeList(): void {
    //数据赋值
    this.subTypeList = [{ id: 0, name: "请选择子类型" }];
    for (var i: number = 0; i < this.typeList.length; i++) {
      if (this.listQuery.type == this.typeList[i].id) {
        this.subTypeList = [];
        for (var j: number = 0; j < this.typeList[i].list.length; j++) {
          this.subTypeList.push(this.typeList[i].list[j]);
        }
        break;
      }
    }
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.addForm = {
      //临时数据
      sort_str: String(row.sort),

      //请求数据
      id: row.id, //ID
      sort: row.sort, //排序
      lang: row.lang, //归属语言，不可编辑
      title: row.title, //标题
      type: row.type, //类型ID
      sub_type: row.sub_type, //子类型
      content: row.content, //消息内容
    };
    this.assFormSubTypeList();

    //显示对话框
    this.dialogVisible = true;
    this.dialogStatus = "update";
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await ryPushTplRemove({ id: row.id });

        //显示提示
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //改变类型
  private onChangeType(type: number): void {
    //列表类型改变
    if (type == 0) {
      //清空数据
      this.listQuery.sub_type = 0;

      //列表赋值
      this.assSubTypeList();
    }
    //编辑类型改变
    else {
      //清空数据
      this.addForm.sub_type = 0;

      //列表赋值
      this.assFormSubTypeList();
    }
  }

  //获取类型
  private getTypeName(type: number): string {
    //数据赋值
    var name: string = "";
    for (var i: number = 0; i < this.typeList.length; i++) {
      if (type == this.typeList[i].id) {
        name = this.typeList[i].name;
        break;
      }
    }

    //返回
    return name;
  }

  //获取子类型名称
  private getSubTypeName(type: number, sub_type: number): string {
    //数据赋值
    var name: string = "";
    for (var i: number = 0; i < this.typeList.length; i++) {
      if (type == this.typeList[i].id) {
        for (var j: number = 0; j < this.typeList[i].list.length; j++) {
          if (sub_type == this.typeList[i].list[j].id) {
            name = this.typeList[i].list[j].name;
            break;
          }
        }
        break;
      }
    }

    //返回
    return name;
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private dialogStatus: string = "";
  private subFormTypeList: any[] = [];
  private dialogVisible: boolean = false;
  private titleMap = { update: "编辑融云推送消息", create: "新增融云推送消息" };

  //添加表单
  private addForm: any = {
    //临时数据
    sort_str: "",

    //请求数据
    id: 0, //ID
    sort: 0, //排序
    lang: "", //归属语言，不可编辑
    title: "", //标题
    type: 0, //类型ID
    sub_type: 0, //子类型
    content: "", //消息内容
  };

  //语言列表
  private langSelect: any[] = [
    { lang: "", name: "请选择语言" },
    { lang: "zh", name: "中文" },
    { lang: "en", name: "英文" },
    { lang: "id", name: "印尼语" },
  ];

  //子类型
  private assFormSubTypeList(): void {
    //数据赋值
    this.subFormTypeList = [{ id: 0, name: "请选择子类型" }];
    for (var i: number = 0; i < this.typeList.length; i++) {
      if (this.addForm.type == this.typeList[i].id) {
        this.subFormTypeList = [];
        for (var j: number = 0; j < this.typeList[i].list.length; j++) {
          this.subFormTypeList.push(this.typeList[i].list[j]);
        }
        break;
      }
    }
  }

  //创建消息
  private async createData() {
    //判断返回
    if (this.addForm.type == 0) return Message({ message: "请选择类型!", type: "error", duration: 5 * 1000 });
    if (this.addForm.sub_type == 0) return Message({ message: "请选择子类型!", type: "error", duration: 5 * 1000 });
    if (this.addForm.title == "") return Message({ message: "请输入标题！", type: "error", duration: 5 * 1000 });
    if (this.addForm.content == "") return Message({ message: "请输入消息内容！", type: "error", duration: 5 * 1000 });
    if (this.addForm.lang == "") return Message({ message: "请选择归属语言！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.sort_str) == 0) return Message({ message: "请输入排序！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.sort = Number(this.addForm.sort_str);

    //添加
    await ryPushTplSave(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });
  }

  //更新消息
  private async updateData() {
    //判断返回
    if (this.addForm.type == 0) return Message({ message: "请选择类型!", type: "error", duration: 5 * 1000 });
    if (this.addForm.sub_type == 0) return Message({ message: "请选择子类型!", type: "error", duration: 5 * 1000 });
    if (this.addForm.title == "") return Message({ message: "请输入标题！", type: "error", duration: 5 * 1000 });
    if (this.addForm.content == "") return Message({ message: "请输入消息内容！", type: "error", duration: 5 * 1000 });
    if (this.addForm.lang == "") return Message({ message: "请选择归属语言！", type: "error", duration: 5 * 1000 });
    if (Number(this.addForm.sort_str) == 0) return Message({ message: "请输入排序！", type: "error", duration: 5 * 1000 });

    //数据赋值
    this.addForm.sort = Number(this.addForm.sort_str);

    //添加
    await ryPushTplSave(this.addForm);

    //隐藏对话框
    this.dialogVisible = false;

    //获取列表
    this.getList();

    //显示提示
    this.$notify({
      title: "成功",
      message: "更新成功",
      type: "success",
      duration: 2000,
    });
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }
}

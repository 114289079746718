//本地导入
import request from "../request";

//切换广场审核状态
export const switchDaily = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/daily/switch",
  });

//广场审核操作
export const dailyStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/daily/status",
  });

//评论列表
export const commentList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/daily/comment/list",
  });

//删除评论
export const removeComment = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/daily/comment/remove",
  });

//广场列表
export const getUserDaily = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/daily",
  });

//头像监管
export const getUserAvatar = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/avatar",
  });

//头像监管修改状态
export const avatarStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/avatar/status",
  });

//头像监管自动审核
export const avatarSwitch = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/avatar/switch",
  });

//获取相册监管
export const getUserPhoto = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/photo",
  });

//头像监管修改状态
export const photoStatus = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/photo/status",
  });

//头像监管自动审核
export const photoSwitch = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/user/photo/switch",
  });

//视频监控
export const videoSupervise = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/risk/medium/list",
  });

//视频监控违规类型
export const videoSuperviseType = () =>
  request({
    method: "post",
    url: "/adm/supervise/risk/medium/label/list",
  });

//视频监控中断操作
export const mediumInterrupt = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/risk/medium/interrupt",
  });

//获取IM监控
export const getSuperviseRiskImList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/supervise/risk/im/list",
  });

//获取IM监控违规类型
export const getSuperviseRiskImLabelList = () =>
  request({
    method: "post",
    url: "/adm/supervise/risk/im/label/list",
  });


//公共库
import { RouteConfig } from "vue-router";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import SidebarItem from "./SidebarItem.vue";
import SidebarLogo from "./SidebarLogo.vue";
import { AppModule } from "@/store/modules/app";
import { SettingsModule } from "@/store/modules/settings";
import { PermissionModule } from "@/store/modules/permission";
import variables, { IScssVariables } from "@/styles/_variables.scss";

//组件
@Component({
  name: "SideBar",
  components: {
    SidebarItem,
    SidebarLogo,
  },
})

//组件函数
export default class extends Vue {
  //侧边栏
  get sidebar(): any {
    return AppModule.sidebar;
  }

  //显示logo
  get showLogo(): boolean {
    return SettingsModule.showSidebarLogo;
  }

  //激活菜单
  get activeMenu(): string {
    //定义变量
    const route = this.$route;
    const { meta, path } = route;

    //数据赋值
    if (meta?.activeMenu) {
      return meta.activeMenu;
    }

    //返回路径
    return path;
  }

  //是否折叠
  get isCollapse(): boolean {
    return !this.sidebar.opened;
  }

  //路由
  get routes(): RouteConfig[] {
    return PermissionModule.routes;
  }

  // 样式参数
  get variables(): IScssVariables {
    return variables;
  }

  //菜单激活颜色
  get menuActiveTextColor(): string {
    //主题颜色
    if (SettingsModule.sidebarTextTheme) {
      return SettingsModule.theme;
    }
    //样式颜色
    else {
      return variables.menuActiveText;
    }
  }
}

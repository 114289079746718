//本地导入
import request from "../request";

//装扮-模型-列表
export const zbModelList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/model/list",
  });

//装扮-模型-保存
export const zbModelSave = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/model/save",
  });

//装扮-模型-删除
export const zbModelRemove = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/model/remove",
  });

//装扮-等级-列表
export const zbLevelList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/level/list",
  });

//装扮-等级-保存
export const zbLevelSave = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/level/save",
  });

//装扮-等级-删除
export const zbLevelRemove = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/level/remove",
  });

//装扮-部位-列表
export const zbPartList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/part/list",
  });

//装扮-配置-列表
export const zbCfgAll = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/cfg/all",
  });

//装扮-道具-列表
export const zbPropList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/prop/list",
  });

//装扮-道具-保存
export const zbPropSave = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/prop/save",
  });

//装扮-道具-删除
export const zbPropRemove = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/prop/remove",
  });

//装扮-道具-发放
export const zbPropSend = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/prop/send",
  });

//装扮-道具-发放记录
export const zbPropSendLog = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/prop/send/log",
  });

//装扮-背包道具-列表
export const zbUserPackList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/user/pack/list",
  });

//装扮-背包道具-删除
export const zbUserPackRemove = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/zb/user/pack/remove",
  });


//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { addUserTag, deleteUserTag, getTagGroupList, getTagList, getUserList, getUserTagList, getNearDetail, getTotalDetail, getReskDetail, exportUserList, exportTotalDetail } from "@/api/request/tag";

//组件
@Component({
  name: "TagsLSearch",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //------------------------------------ 表格 ------------------------------------
  //定义变量
  private list: any[] = [];
  private tagList: any[] = [];
  private selectTagID: number = 0;
  private tagGroupList: any[] = [];
  private listLoading: boolean = false;

  //统计数据
  private total: number = 0;
  private totalUser: number = 0;
  private totalBlogger: number = 0;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    row: 20,

    //时间数据
    regtime: [], //注册时间
    risktime: [], //警报时间
    end_time: "", //注册结束时间
    start_time: "", //注册开始时间
    risk_end_time: "", //警报结束时间
    risk_start_time: "", //警报开始时间

    //本地数据
    id: "", //ID
    userid: 0, //用户ID
    label_id: 0, //标签ID
    excel: false, //是否导出
    sort_filter: [], //筛选数据
    account_type: 0, //账号类型
    label_group_id: 0, //标签组ID
  };

  //时间选择器参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //账号类型列表
  private accountTypeList: any[] = [
    { id: 0, name: "全部" },
    { id: 1, name: "博主" },
    { id: 2, name: "用户" },
  ];

  //创建
  created() {
    //获取标签组
    this.getTagGroupList();

    //获取列表
    this.getTagList();

    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //注册时间赋值
    if (this.listQuery.regtime && this.listQuery.regtime.length == 2) {
      this.listQuery.end_time = `${this.listQuery.regtime[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.regtime[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //警报时间赋值
    if (this.listQuery.risktime && this.listQuery.risktime.length == 2) {
      this.listQuery.risk_end_time = `${this.listQuery.risktime[1]} 23:59:59`;
      this.listQuery.risk_start_time = `${this.listQuery.risktime[0]} 00:00:00`;
    } else {
      this.listQuery.risk_end_time = "";
      this.listQuery.risk_start_time = "";
    }
    this.listQuery.userid = Number(this.listQuery.id);

    //获取数据
    const { data } = await getUserList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.risk_total.total;
    this.totalUser = data.risk_total.total_user;
    this.totalBlogger = data.risk_total.total_blogger;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取列表
  private async getTagList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getTagList();

    //数据赋值
    this.tagList = data;
  }

  //处理导出
  private async handleExport() {
    //显示等待
    this.listLoading = true;

    //注册时间赋值
    if (this.listQuery.regtime && this.listQuery.regtime.length == 2) {
      this.listQuery.end_time = `${this.listQuery.regtime[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.regtime[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //警报时间赋值
    if (this.listQuery.risktime && this.listQuery.risktime.length == 2) {
      this.listQuery.risk_end_time = `${this.listQuery.risktime[1]} 23:59:59`;
      this.listQuery.risk_start_time = `${this.listQuery.risktime[0]} 00:00:00`;
    } else {
      this.listQuery.risk_end_time = "";
      this.listQuery.risk_start_time = "";
    }
    this.listQuery.userid = Number(this.listQuery.id);
    this.listQuery.excel = true;

    //获取数据
    const { data } = await exportUserList(this.listQuery);

    //保存数据
    this.listQuery.time && this.listQuery.time.length == 2 ? saveAs(data, `${this.listQuery.time[0]}--${this.listQuery.time[1]}用户标签列表`) : saveAs(data, "用户标签列表总览");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
      this.listQuery.excel = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //获取标签组
  private async getTagGroupList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await getTagGroupList();

    //数据赋值
    this.tagGroupList = data;
    this.tagGroupList.unshift({ id: 0, name: "全部" });
  }

  //当前标签
  private get nowTagList(): any[] {
    //数据赋值
    var tagList: any[] = [];
    for (var i: number = 0; i < this.tagList.length; i++) {
      if (this.listQuery.label_group_id == 0 || this.listQuery.label_group_id == this.tagList[i].relation_id) {
        tagList.push(this.tagList[i]);
      }
    }
    tagList.unshift({ id: 0, label: "全部" });

    //返回数据
    return tagList;
  }

  //标签组改变
  private handleGroupSelectChange(): void {
    //数据赋值
    this.listQuery.label_id = 0;
  }

  //修改标签
  private async handleChangeTag(row: any) {
    //显示界面
    this.changeTagDialogVisible = true;
    this.changeTagDialoggLoading = true;

    //用户ID
    const { data } = await getUserTagList({ userid: row.userid });

    //数据赋值
    this.changeData = row;
    this.nowSelectTagList = data;

    //隐藏等待
    setTimeout(() => {
      this.changeTagDialoggLoading = false;
    }, 0.5 * 1000);
  }

  //查看近期详情
  private async handleNearDetail(row: any) {
    //显示界面
    this.nearDialogShow = true;

    //显示等待
    this.nearDialogLoading = true;

    //获取数据
    const { data } = await getNearDetail({ userid: row.userid });

    //数据赋值
    this.nearDetailList = data;
    for (var i: number = 0; i < this.nearDetailList.length; i++) {
      this.nearDetailList[i].msg_type = this.handleMessageType(this.nearDetailList[i]);
    }

    //隐藏等待
    setTimeout(() => {
      this.nearDialogLoading = false;
    }, 0.5 * 1000);
  }

  //查看总详情
  private async handleTotalDetail(row: any) {
    //显示界面
    this.totalDialogShow = true;

    //数据赋值
    this.totalListQuery = {
      //时间数据
      lasttime: [], //最后时间
      firsttime: [], //首次时间
      risk_end_time: "", //首次警报结束时间
      risk_start_time: "", //首次警报开始时间
      risk_last_end_time: "", //最后警报结束时间
      risk_last_start_time: "", //最后警报开始时间

      //本地数据
      id: "", //ID
      userid: row.userid, //用户ID
      label_id: 0, //标签ID
      excel: false, //是否导出
      to_userid: "", //接收用户ID
      sort_filter: [], //筛选数据
    };

    //获取数据
    this.getTotalList();
  }

  //获取标签文本
  private getLabelStr(labels: any[]): string {
    //定义变量
    var str: string = "";

    //标签存在
    if (labels.length > 0) {
      //数据赋值
      for (var i: number = 0; i < labels.length; i++) {
        str += labels[i].label + ",";
      }
      str = str.substring(0, str.length - 1);

      //返回数据
      return str;
    }

    //返回结果
    return "--";
  }

  //---------------------------------- 修改标签 ----------------------------------
  //定义变量
  private changeData: any = undefined;
  private nowSelectTagList: any[] = [];
  private changeTagDialogVisible: boolean = false;
  private changeTagDialoggLoading: boolean = false;

  //修改标签改变
  private btnChangeCancel(): void {
    //隐藏界面
    this.changeTagDialogVisible = false;

    //获取数据
    this.getList();
  }

  //当前选择列表
  private get nowCanSelectTagList(): any[] {
    //定义变量
    var list: any[] = [{ id: 0, label: "请选择标签" }];

    //数据赋值
    for (var i: number = 0; i < this.tagList.length; i++) {
      //数据赋值
      var isCan: boolean = true;

      //ID == 0
      if (this.tagList[i].id == 0) isCan = false;

      //正常添加
      if (this.tagList[i].id == 1) isCan = false;

      //已经选择
      for (var j: number = 0; j < this.nowSelectTagList.length; j++) {
        if (this.nowSelectTagList[j].label_id == this.tagList[i].id) {
          isCan = false;
          break;
        }
      }

      //数据赋值
      if (isCan) {
        list.push(this.tagList[i]);
      }
    }

    //返回数据
    return list;
  }

  //移除标签
  private async removeTagTag(index: number) {
    //显示等待
    this.changeTagDialoggLoading = true;
    var id: number = this.nowSelectTagList[index].id;

    //数据赋值
    this.nowSelectTagList.splice(index, 1);

    //删除标签
    await deleteUserTag({ id: id });

    //显示提示
    Message({ message: "操作成功", type: "success", duration: 5 * 1000 });

    //隐藏等待
    setTimeout(() => {
      this.changeTagDialoggLoading = false;
    }, 0.5 * 1000);
  }

  //标签选择
  private async handleTagSelectChange(value: any) {
    //显示等待
    this.changeTagDialoggLoading = true;

    //数据赋值
    for (var i: number = 0; i < this.tagList.length; i++) {
      if (value == this.tagList[i].id) {
        this.nowSelectTagList.push(this.tagList[i]);
        this.nowSelectTagList[this.nowSelectTagList.length - 1].label_id = this.tagList[i].id;
        break;
      }
    }

    //数据赋值
    this.selectTagID = 0;

    //删除标签
    await addUserTag({ label_id: value, userid: this.changeData.userid, risk_type: 1 });

    //显示提示
    Message({ message: "操作成功", type: "success", duration: 5 * 1000 });

    //隐藏等待
    setTimeout(() => {
      this.changeTagDialoggLoading = false;
    }, 0.5 * 1000);
  }

  //---------------------------------- 近期详情 ----------------------------------
  //近期详情
  private nearDetailList: any[] = []; //近期详情
  private nearDialogShow: boolean = false; //显示对话框
  private nearDialogLoading: boolean = false; //对话框等待

  //近期详情取消按钮
  private btnNearCancel(): void {
    //隐藏界面
    this.nearDialogShow = false;

    //清空数据
    this.nearDetailList = [];
  }

  //消息类型
  private handleMessageType(data: any): string {
    //定义变量
    var typeStr: string = "";

    //文本消息
    if (data.msg_type == "RC:TxtMsg" || data.msg_type == "RC:ReferenceMsg") {
      typeStr = "文本消息";
    } else if (data.msg_type == "RC:HQVCMsg") {
      typeStr = "语音消息";
    } else if (data.msg_type == "RC:SightMsg") {
      typeStr = "视频消息";
    } else if (data.msg_type == "RC:ImgMsg") {
      typeStr = "图片消息";
    } else if ((data.msg_type = "RC:VCHangup")) {
      typeStr = data.content;
    }

    //返回
    return typeStr;
  }

  //---------------------------------- 总详情 ----------------------------------
  //近期详情
  private totalDetailList: any[] = []; //近期详情
  private totalDialogShow: boolean = false; //显示对话框
  private totalDialogLoading: boolean = false; //对话框等待

  //列表请求参数
  private totalListQuery: any = {
    //时间数据
    lasttime: [], //最后时间
    firsttime: [], //首次时间
    risk_end_time: "", //首次警报结束时间
    risk_start_time: "", //首次警报开始时间
    risk_last_end_time: "", //最后警报结束时间
    risk_last_start_time: "", //最后警报开始时间

    //本地数据
    id: "", //ID
    userid: 0, //用户ID
    label_id: 0, //标签ID
    excel: false, //是否导出
    to_userid: 0, //接收用户ID
    sort_filter: [], //筛选数据
  };

  //获取数据
  private async getTotalList() {
    //显示等待
    this.totalDialogLoading = true;

    //警报开始赋值
    if (this.totalListQuery.firsttime && this.totalListQuery.firsttime.length == 2) {
      this.totalListQuery.risk_end_time = `${this.totalListQuery.firsttime[1]} 23:59:59`;
      this.totalListQuery.risk_start_time = `${this.totalListQuery.firsttime[0]} 00:00:00`;
    } else {
      this.totalListQuery.risk_end_time = "";
      this.totalListQuery.risk_start_time = "";
    }

    //警报时间赋值
    if (this.totalListQuery.lasttime && this.totalListQuery.lasttime.length == 2) {
      this.totalListQuery.risk_last_end_time = `${this.totalListQuery.lasttime[1]} 23:59:59`;
      this.totalListQuery.risk_last_start_time = `${this.totalListQuery.lasttime[0]} 00:00:00`;
    } else {
      this.totalListQuery.risk_last_end_time = "";
      this.totalListQuery.risk_last_start_time = "";
    }
    this.totalListQuery.to_userid = Number(this.totalListQuery.id);

    //获取数据
    const { data } = await getTotalDetail(this.totalListQuery);

    //数据赋值
    this.totalDetailList = data;

    //隐藏等待
    setTimeout(() => {
      this.totalDialogLoading = false;
    }, 0.5 * 1000);
  }

  //总详情取消按钮
  private btnTotalCancel(): void {
    //隐藏界面
    this.totalDialogShow = false;

    //清空数据
    this.totalDetailList = [];
  }

  //处理导出
  private async handleExportTotal() {
    //显示等待
    this.totalDialogLoading = true;

    //警报开始赋值
    if (this.totalListQuery.firsttime && this.totalListQuery.firsttime.length == 2) {
      this.totalListQuery.risk_end_time = `${this.totalListQuery.firsttime[1]} 23:59:59`;
      this.totalListQuery.risk_start_time = `${this.totalListQuery.firsttime[0]} 00:00:00`;
    } else {
      this.totalListQuery.risk_end_time = "";
      this.totalListQuery.risk_start_time = "";
    }

    //警报时间赋值
    if (this.totalListQuery.lasttime && this.totalListQuery.lasttime.length == 2) {
      this.totalListQuery.risk_last_end_time = `${this.totalListQuery.lasttime[1]} 23:59:59`;
      this.totalListQuery.risk_last_start_time = `${this.totalListQuery.lasttime[0]} 00:00:00`;
    } else {
      this.totalListQuery.risk_last_end_time = "";
      this.totalListQuery.risk_last_start_time = "";
    }
    this.totalListQuery.to_userid = Number(this.totalListQuery.id);
    this.totalListQuery.excel = true;

    //获取数据
    const { data } = await exportTotalDetail(this.totalListQuery);

    //保存数据
    this.listQuery.time ? saveAs(data, `${this.totalListQuery.time[0]}--${this.totalListQuery.time[1]}用户警告详情列表`) : saveAs(data, "用户警告详情列表总览");

    //隐藏等待
    setTimeout(() => {
      this.totalDialogLoading = false;
      this.totalListQuery.excel = false;
    }, 0.5 * 1000);
  }

  //处理所有筛选
  private handleTotalFilter(): void {
    //获取数据
    this.getTotalList();
  }

  //处理总详情
  private async handleShowTotalDetail(row: any) {
    //显示详情

    //显示界面
    this.detailDialogShow = true;

    //显示等待
    this.detailDialogLoading = true;

    //获取数据
    const { data } = await getReskDetail({ userid: row.userid, to_userid: row.to_userid, sort_filter: [] });

    //数据赋值
    this.detailDetailList = data;
    for (var i: number = 0; i < this.detailDetailList.length; i++) {
      this.detailDetailList[i].msg_type = this.handleMessageType(this.detailDetailList[i]);
    }

    //隐藏等待
    setTimeout(() => {
      this.detailDialogLoading = false;
    }, 0.5 * 1000);
  }

  //---------------------------------- 警报详情 ----------------------------------
  //近期详情
  private detailDetailList: any[] = []; //近期详情
  private detailDialogShow: boolean = false; //显示对话框
  private detailDialogLoading: boolean = false; //对话框等待

  //近期详情取消按钮
  private btnDetailCancel(): void {
    //隐藏界面
    this.detailDialogShow = false;

    //清空数据
    this.detailDetailList = [];
  }
}

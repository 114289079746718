
//本地引入
import ResizeMixin from "./mixins/resize";

//公共库
import * as echarts from "echarts";
import { mixins } from "vue-class-component";
import { Component, Prop } from "vue-property-decorator";

//组件
@Component({
  name: "BarChart",
})

//组件函数
export default class extends mixins(ResizeMixin) {
  //组件参数
  @Prop({ default: [] }) private dateList!: any;
  @Prop({ default: [] }) private userList!: any;
  @Prop({ default: "chart" }) private id!: string;
  @Prop({ default: "100%" }) private width!: string;
  @Prop({ default: "(人)" }) private danwei!: string;
  @Prop({ default: "500px" }) private height!: string;
  @Prop({ default: "chart" }) private className!: string;
  @Prop({ default: "活跃用户人数" }) private title!: string;

  //创建
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }

  //销毁前
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  //初始化报表
  private initChart() {
    this.chart = echarts.init(document.getElementById(this.id) as HTMLDivElement);
    this.chart.getZr().off("click");
    this.chart.getZr().on("click", (params) => {
      let _this: any = this;
      const pointInPixel = [params.offsetX, params.offsetY];
      if (_this.chart.containPixel("grid", pointInPixel)) {
        const xIndex = _this.chart.convertFromPixel({ seriesIndex: 0 }, [params.offsetX, params.offsetY])[0];
        this.$emit("indexClick", xIndex);
      }
    });
    const xAxisData: string[] = [];
    const data: number[] = [];
    const data2: number[] = [];
    for (let i = 0; i < 50; i++) {
      xAxisData.push(i.toString());
      data.push((Math.sin(i / 5) * (i / 5 - 10) + i / 6) * 5);
      data2.push((Math.sin(i / 5) * (i / 5 + 10) + i / 6) * 3);
    }
    this.chart.setOption({
      backgroundColor: "#fff",
      title: {
        top: 20,
        text: "报表 ",
        textStyle: {
          fontSize: 16,
          color: "#000",
          fontWeight: "normal",
        },
        left: "1%",
      },
      tooltip: {
        trigger: "axis",
      },
      grid: {
        top: 100,
        left: "2%",
        right: "2%",
        bottom: "2%",
        containLabel: true,
      },
      legend: {
        top: 20,
        right: "4%",
        itemGap: 13,
        icon: "rect",
        itemWidth: 14,
        itemHeight: 5,
        textStyle: {
          fontSize: 12,
          color: "#000",
        },
      },
      xAxis: {
        type: "category",
        data: this.dateList,
      },
      yAxis: [
        {
          type: "value",
          name: this.danwei,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: "#000",
            },
          },
          axisLabel: {
            margin: 10,
            fontSize: 14,
          },
          splitLine: {
            lineStyle: {
              color: "skyblue",
            },
          },
        },
      ],
      series: [
        {
          name: this.title,
          smooth: false,
          symbol: "circle",
          data: this.userList,
          type: "bar",
          symbolSize: 5,
          showSymbol: false,
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.2)",
          },
          itemStyle: {
            normal: { label: { show: true } },
          },
          label: {
            show: true,
          },
        },
      ],
    });
  }
}

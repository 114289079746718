
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import Pagination from "@/comp/Pagination/index.vue";
import { zbPropList, zbPropSend, zbPropSendLog } from "@/api/request/zhuangban";
import { concat } from "lodash";
import GFunc from "@/utils/gFunc";

//组件
@Component({
  name: "QuitCommend",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private porpList: any[] = [];
  private listLoading: boolean = true;

  //请求参数
  private listQuery = {
    //页码数据
    page: 1,
    page_size: 20,

    //临时数据
    time: [],
    user_id_str: "", //用户ID
    adm_id_str: "", //发放人ID

    //请求数据
    adm_id: 0, //发放人ID
    user_id: 0, //用户ID
    prop_id: 0, //道具ID
    end_time: "", //发放结束时间
    start_time: "", //发放开始时间
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
    };
  }

  //创建
  async created() {
    //获取道具列表
    await this.getPorpList();

    //获取数据
    await this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = this.listQuery.time[1];
      this.listQuery.start_time = this.listQuery.time[0];
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }
    this.listQuery.adm_id = Number(this.listQuery.adm_id_str);
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //获取数据
    const { data } = await zbPropSendLog(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;
    for (var i: number = 0; i < this.list.length; i++) {
      this.list[i].status = 0;
      this.list[i].user_ids_short = String(this.list[i].user_ids).substring(0, Math.min(30, String(this.list[i].user_ids).length));
      this.list[i].isShort = this.list[i].user_ids_short.length == String(this.list[i].user_ids).length ? false : true;
    }

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取道具列表
  private async getPorpList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const data: any = await zbPropList({
      //页面数据
      page: 1,
      page_size: 3000,

      //请求数据
      name: "", //名称
      level: 0, //等级
      model_id: 0, //模型ID
    });

    //数据赋值
    this.porpList = data.list.list;
    this.porpList.unshift({ id: 0, name: "请选择道具" });
  }

  //处理发放
  private handleSend(): void {
    //数据赋值
    this.addForm = {
      memo: "", // 说明,必需
      user_ids: [], //用户ID列表
      prop_ids: [], //道具ID列表
    };
    this.tagList = [];
    this.tagInput = "";
    this.selectIndex = 0;
    this.tagInputMore = "";

    //显示界面
    this.dialogVisible = true;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //打开
  private handleOpen(id: number): void {
    //数据赋值
    for (var i: number = 0; i < this.list.length; i++) {
      if (this.list[i].id == id) {
        this.list[i].status = 1;
        break;
      }
    }

    //数据赋值
    var list = GFunc.deepClone(this.list);

    //清空数据
    this.list = [];

    //数据重新赋值
    this.list = list;
  }

  //关闭
  private handleClose(id: number): void {
    //数据赋值
    for (var i: number = 0; i < this.list.length; i++) {
      if (this.list[i].id == id) {
        this.list[i].status = 0;
        break;
      }
    }
  }

  //获取管理部位内容
  private getPropNameList(ids: Array<number>): string {
    //定义变量
    var nameStr: string = "";

    //数据赋值
    for (var i: number = 0; i < ids.length; i++) {
      for (var j: number = 0; j < this.porpList.length; j++) {
        if (ids[i] == this.porpList[j].id) {
          nameStr += this.porpList[j].name + ",";
          break;
        }
      }
    }
    nameStr = nameStr.substring(0, nameStr.length - 1);

    //返回数据
    return nameStr;
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private tagInput: string = "";
  private selectIndex: number = 0;
  private tagList: Array<any> = [];
  private tagInputMore: string = "";
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;

  //添加表单
  private addForm: any = {
    memo: "", // 说明,必需
    user_ids: [], //用户ID列表
    prop_ids: [], //道具ID列表
  };

  //创建消息
  private async sendData() {
    //数据处理
    if (this.addForm.memo == "") return this.$message.error("请输入说明");
    if (this.tagList.length == 0) return this.$message.error("请添加道具");
    if (this.addForm.user_ids.length == 0) return this.$message.error("请添加用户ID");

    //数据赋值
    this.addForm.prop_ids = [];
    for (var i: number = 0; i < this.tagList.length; i++) {
      this.addForm.prop_ids.push(this.tagList[i].id);
    }
    for (var i: number = 0; i < this.addForm.user_ids.length; i++) {
      this.addForm.user_ids[i] = Number(this.addForm.user_ids[i]);
    }

    //保存任务
    const data: any = await zbPropSend(this.addForm);

    //显示提示
    this.$message.success(data.msg);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //获取列表
    this.getList();
  }

  //选择改变
  private onChangeSelect(): void {
    //数据赋值
    if (this.selectIndex != 0) {
      for (var i: number = 0; i < this.selectPropList.length; i++) {
        if (this.selectIndex == this.selectPropList[i].id) {
          this.tagList.push(this.selectPropList[i]);

          break;
        }
      }

      //清空数据
      this.selectIndex = 0;
    }
  }

  //添加目标用户
  private addUserTag(type: any): void {
    //批量添加
    if (type === "more") {
      //定义变量
      let errorList: any = [];

      //数据处理
      this.tagInputMore
        .trim()
        .split(",")
        .forEach((item) => {
          if (!/^[1-9]\d*$/.test(item.trim())) return errorList.push(item.trim());
          this.addForm.user_ids.push(item.trim());
          this.tagInputMore = ""; // 清空输入框
        });

      //显示异常提示
      if (errorList.length) this.$message.error(`${errorList.join(",")}不属于正确格式，请输入数字并以英文格式的逗号进行分隔`);
    } else {
      //数据处理
      if (/^[1-9]\d*$/.test(this.tagInput.trim()) && this.tagInput.trim() !== "") {
        this.addForm.user_ids.push(this.tagInput.trim());
        this.tagInput = ""; // 清空输入框
      }
      //异常处理
      else {
        this.$message.error("请输入正确形式的ID");
        this.tagInput = "";
      }
    }
  }

  //移除目标用户
  private removeUserTag(index: any): void {
    this.addForm.user_ids.splice(index, 1);
  }

  //移除目标用户
  private removePropTag(index: any): void {
    this.tagList.splice(index, 1);
  }

  //选择类标
  private get selectPropList(): Array<any> {
    //定义变量
    var selectList: Array<any> = [];

    //数据赋值
    for (var i: number = 0; i < this.porpList.length; i++) {
      //数据赋值
      var isAdd: boolean = true;
      for (var j: number = 0; j < this.tagList.length; j++) {
        if (this.porpList[i].id == this.tagList[j].id) {
          isAdd = false;
          break;
        }
      }
      if (isAdd) selectList.push(this.porpList[i]);
    }

    //返回数据
    return selectList;
  }
}


//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";
import { zbModelList, zbModelRemove, zbModelSave, zbPartList } from "@/api/request/zhuangban";

//组件
@Component({
  name: "ZBModel",
})

//组件函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private partList: any[] = [];
  private listLoading: boolean = true;

  //创建
  async created() {
    //获取装扮部位
    await this.getPartList();

    //获取数据
    await this.getList();
  }

  //获取数据
  private async getList() {
    //显示加载
    this.listLoading = true;

    //获取记录
    const { data } = await zbModelList({});

    //数据赋值
    this.list = data;

    //隐藏加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //获取装扮部位
  private async getPartList() {
    //显示加载
    this.listLoading = true;

    //获取记录
    const { data } = await zbPartList({});

    //数据赋值
    this.partList = data;
  }

  //处理创建
  private handlerCreate(): void {
    //数据赋值
    this.addForm = {
      id: 0, // 模型ID
      name: "", // 名称
      memo: "", // 说明
      part_ids: [], // 关联部位ID
    };
    this.tagList = [];
    this.selectIndex = 0;
    this.dialogStatus = "create";

    //显示界面
    this.dialogVisible = true;
  }

  //处理创建
  private handleUpdate(row: any): void {
    //数据赋值
    this.addForm = {
      id: row.id, // 模型ID
      name: row.name, // 名称
      memo: row.memo, // 说明
      part_ids: row.part_ids, // 关联部位ID
    };
    this.selectIndex = 0;
    this.dialogStatus = "update";
    this.tagList = this.getPartNameList(row.part_ids);

    //显示界面
    this.dialogVisible = true;
  }

  //处理创建
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await zbModelRemove({ id: row.id });

        //显示提示
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //获取管理部位内容
  private getPartNameList(ids: Array<number>): Array<any> {
    //定义变量
    var nameList: Array<any> = [];

    //数据赋值
    for (var i: number = 0; i < ids.length; i++) {
      for (var j: number = 0; j < this.partList.length; j++) {
        if (ids[i] == this.partList[j].id) {
          nameList.push(this.partList[j]);
          break;
        }
      }
    }

    //返回数据
    return nameList;
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private selectIndex: number = 0;
  private tagList: Array<any> = [];
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;
  private titleMap = { update: "编辑装扮模型", create: "新增装扮模型" };

  //添加表单
  private addForm: any = {
    id: 0, // 模型ID
    name: "", // 名称
    memo: "", // 说明
    part_ids: [], // 关联部位ID
  };

  //创建消息
  private async createData() {
    //数据处理
    if (this.addForm.name == "") return this.$message.error("请输入名称");
    if (this.tagList.length == 0) return this.$message.error("请添加关联部位");

    //数据赋值
    this.addForm.part_ids = [];
    for (var i: number = 0; i < this.tagList.length; i++) {
      this.addForm.part_ids.push(this.tagList[i].id);
    }

    //保存任务
    await zbModelSave(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //获取列表
    this.getList();
  }

  //更新消息
  private async updateData() {
    //数据处理
    if (this.addForm.name == "") return this.$message.error("请输入名称");
    if (this.tagList.length == 0) return this.$message.error("请添加关联部位");

    //数据赋值
    this.addForm.part_ids = [];
    for (var i: number = 0; i < this.tagList.length; i++) {
      this.addForm.part_ids.push(this.tagList[i].id);
    }

    //保存任务
    await zbModelSave(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //获取列表
    this.getList();
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //选择改变
  private onChangeSelect(): void {
    //数据赋值
    if (this.selectIndex != 0) {
      for (var i: number = 0; i < this.selectList.length; i++) {
        if (this.selectIndex == this.selectList[i].id) {
          this.tagList.push(this.selectList[i]);

          break;
        }
      }

      //清空数据
      this.selectIndex = 0;
    }
  }

  //移除目标用户
  private removeTag(index: any): void {
    this.tagList.splice(index, 1);
  }

  //选择类标
  private get selectList(): Array<any> {
    //定义变量
    var selectList: Array<any> = [];

    //数据赋值
    for (var i: number = 0; i < this.partList.length; i++) {
      //数据赋值
      var isAdd: boolean = true;
      for (var j: number = 0; j < this.tagList.length; j++) {
        if (this.partList[i].id == this.tagList[j].id) {
          isAdd = false;
          break;
        }
      }
      if (isAdd) selectList.push(this.partList[i]);
    }
    selectList.unshift({ id: 0, name: "请选择关联部位" });

    //返回数据
    return selectList;
  }
}

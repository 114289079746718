
//本地引入
import settings from "../../settings";
import { AppModule } from "@/store/modules/app";

//公共库
import { Component, Vue } from "vue-property-decorator";

//组件
@Component({
  name: "LangSelect",
})

//界面函数
export default class extends Vue {
  //当前语言
  get language() {
    return AppModule.language;
  }

  //处理设置语言
  private handleSetLanguage(lang: string) {
    //数据赋值
    this.$i18n.locale = lang;

    //设置语言
    AppModule.setLanguage(lang);
    document.documentElement.lang = lang;

    //重新显示标题
    const title = `${this.$route.meta?.title} - ${settings.title}`;
    document.title = title;

    //显示成功提示
    this.$message({
      message: this.$t("components.changeLanguageTips").toString(),
      type: "success",
    });
  }
}


//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getUserDaily, switchDaily, dailyStatus, commentList, removeComment } from "@/api/request/supervise";

//组件
@Component({
  name: "Square",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private switchStatus: number = 0;
  private listLoading: boolean = true;
  private switchLoading: boolean = false;

  //请求数据
  private listQuery: any = {
    //页码数据
    page: 1,
    row: 20,

    //请求数据
    type: 1,
    userid: undefined,
  };

  //类型数据
  private typeOptions: any[] = [
    { value: 1, label: "广场" },
    { value: 2, label: "回复" },
  ];

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    if (this.listQuery.userid) this.listQuery.userid = Number(this.listQuery.userid);

    //获取数据
    const { data } = await getUserDaily(this.listQuery);

    //数据赋值
    data.list.map((item: any) => {
      let picArr = item.pics.split(",");
      return (item.pics = picArr);
    });
    this.list = data.list;
    this.total = data.total;
    this.switchStatus = data.switch;

    //隐藏的鞥带
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理重置
  private handleReset(): void {
    //数据赋值
    this.listQuery.page = 1;
    this.listQuery.userid = undefined;

    //获取数据
    this.getList();
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //切换
  private async switchChange(val: any) {
    //显示等待
    this.switchLoading = true;

    //切换
    const res = await switchDaily({ switch: val });

    //操作成功
    if (res.status === 0) {
      //显示提示
      this.$message.success("修改审核状态成功");

      //隐藏等待
      this.switchLoading = false;

      //获取数据
      this.getList();
    }
  }

  //状态处理
  private statusFormat(row: any): string {
    if (row.status === 1) return "通过";
    if (row.status === 3) return "拒绝";
    if (row.status === 5) return "删除";
    if (row.status === 7) return "待审核";
    return "";
  }

  //修改状态
  private changeStatus(id: any, type: any): void {
    //数据赋值
    let text: any;
    if (type === 1) text = "通过";
    if (type === 3) text = "拒绝";
    if (type === 5) text = "删除";

    //显示提示
    this.$confirm(`确定要${text}？`, "警告", {
      confirmButtonText: "确定" as string,
      cancelButtonText: "取消" as string,
      type: "warning",
    })
      .then(async () => {
        //请求修改
        const res = await dailyStatus({ id, status: type, type: this.listQuery.type });

        //修改成功
        if (res.status === 0) {
          //显示提示
          this.$message.success(`${text}成功`);

          //获取数据
          this.getList();
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //查看评论
  private showComment(id: number): void {
    //数据赋值
    this.commentQuery = {
      id: id,
    };

    //显示界面
    this.commentDialogVisible = true;

    //获取数据
    this.getCmmentList();
  }

  //---------------------- 查看评论 --------------------
  //定义变量
  private commentList: any[] = [];
  private commentDialogVisible: boolean = false; //是否显示
  private commentDialogLoading: boolean = false; //对话框等待

  //表单数据
  private commentQuery: any = {
    id: 0, // 动态ID
  };

  //获取评论列表
  private async getCmmentList() {
    //清空数据
    this.commentList = [];

    //显示等待
    this.commentDialogLoading = true;

    //获取数据
    const { data } = await commentList(this.commentQuery);

    //数据赋值
    this.commentList = data;

    //隐藏的鞥带
    setTimeout(() => {
      this.commentDialogLoading = false;
    }, 0.5 * 1000);
  }

  //显示评论回复
  private showRepeat(id: number, list: any[]): void {
    //数据赋值
    this.id = id;
    this.repeatList = list;

    //显示界面
    this.repeatDialogVisible = true;
  }

  //删除评论
  private deleteComment(id: number): void {
    //显示提示
    this.$confirm(`您确定要删除该评论？`, "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //删除PK赛
        const res = await removeComment({ id: id });

        //重新获取列表
        this.getCmmentList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //---------------------- 品论回复 --------------------
  //定义变量
  private id: number = 0;
  private repeatList: any[] = [];
  private repeatDialogVisible: boolean = false; //是否显示

  //删除评论
  private deleteReport(id: number): void {
    //显示提示
    this.$confirm(`您确定要删除该评论回复？`, "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //删除PK赛
        const res = await removeComment({ id: id });

        //重新获取列表
        await this.getCmmentList();

        //隐藏界面
        this.repeatDialogVisible = false;
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

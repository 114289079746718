
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { zbCfgAll, zbPropList, zbPropRemove, zbPropSave } from "@/api/request/zhuangban";

//组件
@Component({
  name: "ZBProp",
  components: {
    Pagination,
  },
})

//组件函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private partsList: any[] = [];
  private modelsList: any[] = [];
  private levelsList: any[] = [];
  private listLoading: boolean = true;

  //请求数据
  private listQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //请求数据
    name: "", //名称
    level: 0, //等级
    model_id: 0, //模型ID
  };

  //创建
  created() {
    //获取数据
    this.getCfg();

    //获取数据
    this.getList();
  }

  //获取数据
  private async getCfg() {
    //显示加载
    this.listLoading = true;

    //获取记录
    const { data } = await zbCfgAll(this.listQuery);

    //数据赋值
    this.partsList = data.parts;
    this.modelsList = data.models;
    this.levelsList = data.levels;
    this.partsList.unshift({ id: 0, name: "请选择部位" });
    this.modelsList.unshift({ id: 0, name: "请选择模型" });
    this.levelsList.unshift({ id: 0, name: "请选择等级" });
  }

  //获取数据
  private async getList() {
    //显示加载
    this.listLoading = true;

    //获取记录
    const data: any = await zbPropList(this.listQuery);

    //数据赋值
    this.list = data.list.list;
    this.total = data.list.total;

  //数据赋值
  



    //隐藏加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理创建
  private handlerCreate(): void {
    //数据赋值
    this.addForm = {
      //临时数据
      value_str: "", //价值

      //请求数据
      id: 0, //ID
      name: "", //名称
      icon: "", //图标
      level: 0, //等级
      brief: "", //简介
      role: 0, //角色限制
      model_id: 0, //模型ID
      value: undefined, //价值
    };
    this.picUrl = null;
    this.picFileList = [];
    this.dialogStatus = "create";

    //显示界面
    this.dialogVisible = true;
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.addForm = {
      //请求数据
      id: row.id, //ID
      name: row.name, //名称
      icon: row.icon, //图标
      value: row.value, //价值
      level: row.level, //等级
      brief: row.brief, //简介
      role: row.role, //角色限制
      model_id: row.model_id, //模型ID
    };
    this.picUrl = null;
    this.picFileList = [];
    this.picUrl = row.icon;
    this.dialogStatus = "update";
    this.picFileList = [{ name: "name.jpg", url: row.icon }];

    //显示界面
    this.dialogVisible = true;
  }

  //处理创建
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await zbPropRemove({ id: row.id });

        //显示提示
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;
  private titleMap = { update: "编辑装扮道具", create: "新增装扮道具" };

  //添加表单
  private addForm: any = {
    //请求数据
    id: 0, //ID
    name: "", //名称
    icon: "", //图标
    level: 0, //等级
    brief: "", //简介
    role: 0, //角色限制
    model_id: 0, //模型ID
    value: undefined, //价值
  };

  //角色限制选择
  private roleSelect: any[] = [
    { id: 0, name: "无限制" },
    { id: 1, name: "限博主" },
    { id: 2, name: "限用户" },
  ];

  //显示显示
  private onKeyUp(): void {
    if (this.addForm.value) {
      this.addForm.value = Number(this.addForm.value) ? Number(this.addForm.value) : undefined;
    }
  }

  //创建消息
  private async createData() {
    //数据处理
    if (!this.picUrl) return this.$message.error("请上传图标");
    if (this.addForm.name == "") return this.$message.error("请输入名称");
    if (this.addForm.level == 0) return this.$message.error("请选择等级");
    if (this.addForm.brief == "") return this.$message.error("请输入简介");
    if (this.addForm.model_id == 0) return this.$message.error("请选择模型");
    if (this.addForm.value == undefined) return this.$message.error("请输入价值");

    //数据赋值
    this.addForm.icon = this.picUrl;

    //保存任务
    await zbPropSave(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //显示提示
    this.$notify({
      title: "成功",
      message: "添加成功",
      type: "success",
      duration: 2000,
    });

    //获取列表
    this.getList();
  }

  //更新消息
  private async updateData() {
    //数据处理
    if (!this.picUrl) return this.$message.error("请上传图标");
    if (this.addForm.name == "") return this.$message.error("请输入名称");
    if (this.addForm.level == 0) return this.$message.error("请选择等级");
    if (this.addForm.brief == "") return this.$message.error("请输入简介");
    if (this.addForm.model_id == 0) return this.$message.error("请选择模型");
    if (this.addForm.value == undefined) return this.$message.error("请输入价值");

    //数据赋值
    this.addForm.icon = this.picUrl;

    //保存任务
    await zbPropSave(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //显示提示
    this.$notify({
      title: "成功",
      message: "编辑成功",
      type: "success",
      duration: 2000,
    });

    //获取列表
    this.getList();
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //----------------------------- 图片处理 -----------------------------
  //定义变量
  private picUrl: any = null;
  private picFileList: any[] = [];
  private getHeads = getHeaders();
  private getAction = this.getApi();

  //获取API
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //图片图片预览
  private handlePicPreviewCommon(file: any): void {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示对话框
    this.dialogImageVisible = true;
  }

  //上传图片
  private handlePicBeforeUploadCommon(file: any): any {
    //数据赋值
    const index = file.name.lastIndexOf(".");
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];
    const isLt2M = file.size / 1024 / 1024 < 10;

    //文件过大
    if (!isLt2M) {
      //显示提示
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出10M",
        type: "warning",
        duration: 2000,
      });

      //返回十八
      return false;
    }
    //格式不正确
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //移除图片
  private handlePicRemove(file: any, fileList: any): void {
    this.picUrl = null;
    this.picFileList = [];
  }

  //上传图片成功
  private handlePicUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.picFileList = fileList;
    this.picUrl = response.data.images[0];
  }

  //--------------------------------- 显示图片 ---------------------------------
  //定义变量
  private dialogImageVisible = false;
  private dialogImageUrl: any = null;

  //打开图片
  private openImg(url: string) {
    //数据赋值
    this.dialogImageUrl = url;

    //显示界面
    this.dialogImageVisible = true;
  }
}


//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { assistBloggerUserMatch } from "@/api/request/blogger";

//组件
@Component({
  name: "UserMatch",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求参数
  private listQuery: any = {
    //页码数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "", //用户ID
    blogger_id_str: "", // 博主ID

    //请求数据
    user_id: 0, // 用户ID
    blogger_id: 0, // 博主ID
    pick_status: -1, //选择状态
  };

  //状态列表
  private statusList: any[] = [
    { status: -1, name: "全部" },
    { status: 0, name: "未选择" },
    { status: 1, name: "已选择" },
  ];

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.blogger_id = Number(this.listQuery.blogger_id_str);

    //获取数据
    const { data } = await assistBloggerUserMatch(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }
}

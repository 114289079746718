
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";
import { zbLevelList, zbLevelRemove, zbLevelSave } from "@/api/request/zhuangban";

//组件
@Component({
  name: "ZBLevel",
})

//组件函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private listLoading: boolean = true;

  //创建
  async created() {
    //获取数据
    await this.getList();
  }

  //获取数据
  private async getList() {
    //显示加载
    this.listLoading = true;

    //获取记录
    const { data } = await zbLevelList({});

    //数据赋值
    this.list = data;

    //隐藏加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理创建
  private handlerCreate(): void {
    //数据赋值
    this.addForm = {
      //临时数据
      level_str: "",

      //请求数据
      id: 0, //ID
      name: "", //名称
      level: 0, //等级
      color: "", //颜色
    };
    this.dialogStatus = "create";

    //显示界面
    this.dialogVisible = true;
  }

  //处理创建
  private handleUpdate(row: any): void {
    //数据赋值
    this.addForm = {
      //临时数据
      level_str: String(row.level),

      //请求数据
      id: row.id, //ID
      name: row.name, //名称
      level: row.level, //等级
      color: row.color, //颜色
    };
    this.dialogStatus = "update";

    //显示界面
    this.dialogVisible = true;
  }

  //处理创建
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await zbLevelRemove({ id: row.level });

        //显示提示
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;
  private titleMap = { update: "编辑装扮等级", create: "新增装扮等级" };

  //添加表单
  private addForm: any = {
    //临时数据
    level_str: "",

    //请求数据
    id: 0, //ID
    name: "", //名称
    level: 0, //等级
    color: "", //颜色
  };

  //创建消息
  private async createData() {
    //数据处理
    if (this.addForm.name == "") return this.$message.error("请输入名称");
    if (Number(this.addForm.level_str) == 0) return this.$message.error("请添输入等级");

    //数据赋值
    this.addForm.level = Number(this.addForm.level_str);

    //保存任务
    await zbLevelSave(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //获取列表
    this.getList();
  }

  //更新消息
  private async updateData() {
    //数据处理
    if (this.addForm.name == "") return this.$message.error("请输入名称");
    if (Number(this.addForm.level_str) == 0) return this.$message.error("请添输入等级");

    //数据赋值
    this.addForm.level = Number(this.addForm.level_str);
    //保存任务
    await zbLevelSave(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //获取列表
    this.getList();
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }
}

//本地引入
import store from "@/store";
import { UserModule } from "./user";
import { CfgAnalyze } from "@/utils/cfg-analyze";
import { asyncRoutes, constantRoutes } from "@/router";

//公共库
import { RouteConfig } from "vue-router";
import { VuexModule, Module, Mutation, Action, getModule } from "vuex-module-decorators";

//权限状态结果
export interface IPermissionState {
  routes: RouteConfig[];
  dynamicRoutes: RouteConfig[];
}

//检测权限
export const checkHasPermission = (route: any) => {
  //数据赋值
  const routeCfg = UserModule.routeCfg; //路由权限列表
  const roleRouteCfg = UserModule.roleRouteCfg; //角色权限列表
  const roleRoutes = toRouteFromID(roleRouteCfg, routeCfg); //用户路由

  //返回结果
  return hasPermissionBy(roleRoutes, route);
};

//将权限ID转为路由
const toRouteFromID = (roleRouteCfg: any, routeCfg: any) => {
  //定义变量
  const routes: string[] = [];
  const isAdmin = roleRouteCfg && roleRouteCfg[0] === "*"; //是否管理员

  //路由处理
  routeCfg.forEach((cfg: { m_id: number; m_route: string }) => {
    const mid = cfg.m_id;
    if (isAdmin || (mid && mid > 0 && roleRouteCfg.indexOf(mid) !== -1)) {
      routes.push(cfg.m_route);
    }
  });

  //返回路由
  return routes;
};

//过滤动态路由
const filterAsyncRoutesBy = (routes: RouteConfig[], roleRoutes: string[]) => {
  //定义变量
  const res: RouteConfig[] = [];

  //数据处理
  routes.forEach((route) => {
    const r = { ...route };
    const has = hasPermissionBy(roleRoutes, r, true);
    if (has) {
      if (r.children) {
        r.children = filterAsyncRoutesBy(r.children, roleRoutes);
      }
      res.push(r);
    }
  });

  //返回结果
  return res;
};

//检查是否有权限
const hasPermissionBy = (roleRoutes: string[], route: RouteConfig, ignorable = false) => {
  //过滤包类型，超级管理员放行
  let packTypeFilter = false; //包类型是否被过滤
  if (route.meta && route.meta.filterPackTypes && route.meta.filterPackTypes.length > 0) {
    const packType = CfgAnalyze.getPackType();
    packTypeFilter = roleRoutes.some(() => route.meta?.filterPackTypes.includes(packType));
  }
  if (packTypeFilter) {
    return false;
  }

  //获取允许的包类型
  let packTypeEnable = true; // 包类型是否允许
  if (route.meta && route.meta.enablePackTypes && route.meta.enablePackTypes.length > 0) {
    const packType = CfgAnalyze.getPackType();
    packTypeEnable = roleRoutes.some(() => route.meta?.enablePackTypes.includes(packType));
  }
  if (!packTypeEnable) return false;

  //是否特殊需求
  if (route.meta && route.meta.canShow !== undefined && route.meta.canShow === false) {
    return false;
  }

  //超管，拥有所有权限，放行
  const roleRouteCfg = UserModule.roleRouteCfg;
  if (roleRouteCfg && roleRouteCfg[0] === "*") return true;

  //过滤路由
  if (route.meta && route.meta.routeCfgs && route.meta.routeCfgs.length > 0) {
    if (ignorable && route.meta.ignorable) {
      return true;
    }
    return roleRoutes.some((roleRoute) => route.meta?.routeCfgs.includes(roleRoute));
  } else {
    return true;
  }
};

/**
 * 权限模块
 */
@Module({ dynamic: true, store, name: "permission" })
class Permission extends VuexModule implements IPermissionState {
  //定义变量
  public routes: RouteConfig[] = [];
  public dynamicRoutes: RouteConfig[] = [];

  //------------------ 本地函数 ------------------
  //设置路由
  @Mutation
  private SET_ROUTES(routes: RouteConfig[]) {
    this.routes = constantRoutes.concat(routes);
    this.dynamicRoutes = routes;
  }

  //------------------ 外部调用 ------------------
  //生成路由
  @Action
  public generateRoutes() {
    //数据赋值
    const routeCfg = UserModule.routeCfg;
    const roleRouteCfg = UserModule.roleRouteCfg;
    const roleRoutes = toRouteFromID(roleRouteCfg, routeCfg);
    const accessedRoutes = filterAsyncRoutesBy(asyncRoutes, roleRoutes);

    //设置路由
    this.SET_ROUTES(accessedRoutes);
  }
}

//导出
export const PermissionModule = getModule(Permission);

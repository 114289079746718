
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { getAppBaseApi, getHeaders } from "@/api/request";
import { bootImageList, removeBootImage, saveBootImage } from "@/api/request/system";

//组件
@Component({
  name: "BootImage",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求参数
  private listQuery = {
    //页码数据
    page: 1,
    page_size: 20,
  };

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await bootImageList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理创建
  private handleCreate(): void {
    //数据赋值
    this.addForm = {
      //请求数据
      id: 0, //ID
      type: 0, //类型
      name: "", //名称
      image: "", //图片
      status: 0, //状态
    };
    this.picUrl = null;
    this.picFileList = [];
    this.dialogStatus = "create";

    //显示界面
    this.dialogVisible = true;
  }

  //处理编辑
  private handleUpdate(row: any): void {
    //数据赋值
    this.addForm = {
      //请求数据
      id: row.id, //ID
      name: row.name, //名称
      type: row.type, //类型
      image: row.image, //图片
      status: row.status, //状态
    };
    this.picUrl = null;
    this.picFileList = [];
    this.picUrl = row.image;
    this.dialogStatus = "update";
    this.picFileList = [{ name: "name.jpg", url: row.image }];

    //显示界面
    this.dialogVisible = true;
  }

  //处理删除
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await removeBootImage({ id: row.id });

        //显示提示
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //--------------------------------- 添加/编辑 ---------------------------------
  //定义变量
  private dialogStatus: string = "";
  private dialogVisible: boolean = false;
  private dialogLoading: boolean = false;
  private titleMap = { update: "编辑开机图片", create: "新增开机图片" };

  //添加表单
  private addForm: any = {
    //临时数据
    blogger_id_str: "",

    //请求数据
    id: 0, // ID
    type: 0, //类型
    name: "", //名称
    image: "", //图片
    status: 0, //状态
  };

  //类型选择
  private typeSelect: any[] = [
    { id: 0, name: "全部" },
    { id: 1, name: "博主" },
    { id: 2, name: "用户" },
  ];

  //状态选择
  private statusSelect: any[] = [
    { id: 0, name: "正常" },
    { id: 1, name: "禁用" },
  ];

  //创建消息
  private async createData() {
    //数据处理
    if (!this.picUrl) return this.$message.error("请上传图片");
    if (this.addForm.name == "") return this.$message.error("请输入名称");

    //数据赋值
    this.addForm.image = this.picUrl;

    //保存任务
    await saveBootImage(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //显示提示
    this.$notify({
      title: "成功",
      message: "创建成功",
      type: "success",
      duration: 2000,
    });

    //获取列表
    this.getList();
  }

  //更新消息
  private async updateData() {
    //数据处理
    if (!this.picUrl) return this.$message.error("请上传图片");
    if (this.addForm.name == "") return this.$message.error("请输入名称");

    //数据赋值
    this.addForm.image = this.picUrl;

    //保存任务
    await saveBootImage(this.addForm);

    //隐藏等待
    this.dialogLoading = false;

    //隐藏界面
    this.dialogVisible = false;

    //显示提示
    this.$notify({
      title: "成功",
      message: "编辑成功",
      type: "success",
      duration: 2000,
    });

    //获取列表
    this.getList();
  }

  //是否创建
  private isCreate(): boolean {
    return this.dialogStatus === "create";
  }

  //----------------------------- 图片处理 -----------------------------
  //定义变量
  private picUrl: any = null;
  private picFileList: any[] = [];
  private getHeads = getHeaders();
  private getAction = this.getApi();

  //获取API
  private getApi(): string {
    const reg = /^https?\:\/\//;
    const baseApi = getAppBaseApi();
    const isMatch = reg.test(baseApi);
    const prefix = !isMatch ? window.location.origin : "";
    return `${prefix}${baseApi}/adm/picture/files`;
  }

  //图片图片预览
  private handlePicPreviewCommon(file: any): void {
    //数据赋值
    this.dialogImageUrl = file.url;

    //显示对话框
    this.dialogImageVisible = true;
  }

  //上传图片
  private handlePicBeforeUploadCommon(file: any): any {
    //数据赋值
    const index = file.name.lastIndexOf(".");
    const extension = file.name.substr(index + 1);
    const extensionList = ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG", "gif", "svga"];
    const isLt2M = file.size / 1024 / 1024 < 10;

    //文件过大
    if (!isLt2M) {
      //显示提示
      this.$notify({
        title: "失败",
        message: "图片或动画资源不可超出10M",
        type: "warning",
        duration: 2000,
      });

      //返回十八
      return false;
    }
    //格式不正确
    else if (extensionList.indexOf(extension) < 0) {
      this.$notify({
        title: "失败",
        message: "当前文件格式不支持",
        type: "error",
        duration: 2000,
      });
      return false;
    }
  }

  //移除图片
  private handlePicRemove(file: any, fileList: any): void {
    this.picUrl = null;
    this.picFileList = [];
  }

  //上传图片成功
  private handlePicUploadSuccess(response: any, file: any, fileList: any): void {
    //数据赋值
    this.picFileList = fileList;
    this.picUrl = response.data.images[0];
  }

  //--------------------------------- 显示图片 ---------------------------------
  //定义变量
  private dialogImageVisible = false;
  private dialogImageUrl: any = null;

  //打开图片
  private openImg(url: string) {
    //数据赋值
    this.dialogImageUrl = url;

    //显示界面
    this.dialogImageVisible = true;
  }
}


//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { zbUserPackList, zbUserPackRemove } from "@/api/request/zhuangban";

//组件
@Component({
  name: "ZBUserPack",
  components: {
    Pagination,
  },
})

//组件函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求数据
  private listQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "", //用户ID
    prop_id_str: "", // 道具ID

    //请求数据
    user_id: 0, // 用户ID
    prop_id: 0, // 道具ID
  };

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示加载
    this.listLoading = true;

    //数据赋值
    this.listQuery.user_id = Number(this.listQuery.user_id_str);
    this.listQuery.prop_id = Number(this.listQuery.prop_id_str);

    //获取记录
    const { data } = await zbUserPackList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理创建
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await zbUserPackRemove({ id: row.id });

        //显示提示
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

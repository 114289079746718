
//公共库
import { compile } from "path-to-regexp";
import { RouteRecord, Route } from "vue-router";
import { Component, Vue, Watch } from "vue-property-decorator";

//组件
@Component({
  name: "Breadcrumb",
})

//界面函数
export default class extends Vue {
  //定义变量
  private breadcrumbs: RouteRecord[] = [];

  //创建调用
  created() {
    this.getBreadcrumb();
  }

  //获取导航
  private getBreadcrumb() {
    //数据赋值
    let matched = this.$route.matched.filter((item) => item.meta && item.meta.title);
    const first = matched[0];

    //是否首页
    if (!this.isDashboard(first)) {
      matched = [{ path: "/dashboard", meta: { title: "首页" } } as any].concat(matched);
    }

    //数据赋值
    this.breadcrumbs = matched.filter((item) => {
      return item.meta && item.meta.title && item.meta.breadcrumb !== false;
    });
  }

  //路由改变
  @Watch("$route")
  private onRouteChange(route: Route) {
    //重定向不更新
    if (route.path.startsWith("/redirect/")) {
      return;
    }

    //获取导航
    this.getBreadcrumb();
  }

  //处理链接
  private handleLink(item: any): void {
    //数据赋值
    const { redirect, path } = item;

    //重定向
    if (redirect) {
      this.$router.push(redirect).catch((err) => {});
      return;
    }

    //切换
    this.$router.push(this.pathCompile(path)).catch((err) => {
      console.warn(err);
    });
  }

  //路径编译
  private pathCompile(path: string): any {
    //数据赋值
    const { params } = this.$route;
    const toPath = compile(path);

    //返回结果
    return toPath(params);
  }

  //是否首页
  private isDashboard(route: RouteRecord): boolean {
    //数据赋值
    const name = route && route.name;

    //不存在
    if (!name) return false;

    //返回结果
    return name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase();
  }
}

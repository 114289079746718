
//公共库
import { Message, MessageBox } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { bottleList, removeBottle, bottleChatList, bottlePickList } from "@/api/request/bottle";

//组件
@Component({
  name: "BottleList",
  components: {
    Pagination,
  },
})

//组件函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求数据
  private listQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    id_str: "", //ID
    owner_id_str: "", //创建者ID
    picker_id_str: "", //拾取者ID

    //请求数据
    id: 0, //ID
    owner_id: 0, //创建者ID
    picker_id: 0, //拾取者ID
  };

  //创建
  created() {
    //获取数据
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示加载
    this.listLoading = true;

    //数据赋值
    this.listQuery.id = Number(this.listQuery.id_str);
    this.listQuery.owner_id = Number(this.listQuery.owner_id_str);
    this.listQuery.picker_id = Number(this.listQuery.picker_id_str);

    //获取记录
    const { data } = await bottleList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏加载
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //消息列表
  private handleChat(row: any): void {
    //数据赋值
    this.chatQuery = {
      //页面数据
      page: 1,
      page_size: 20,

      //临时数据
      user_id_str: "", //用户ID

      //请求数据
      user_id: 0, //用户ID
      bottle_id: row.id, //漂流瓶ID
    };

    //显示对话框
    this.chatDialogVisible = true;

    //获取数据
    this.getChatList();
  }

  //漂流瓶内容
  private handleDetail(content: any): void {
    //数据赋值
    this.detail = content;

    //显示界面
    this.detailDialogVisible = true;
  }

  //拾取列表
  private handlePacker(row: any): void {
    //数据赋值
    this.packerQuery = {
      //页面数据
      page: 1,
      page_size: 20,

      //临时数据
      user_id_str: "", //用户ID

      //请求数据
      user_id: 0, //用户ID
      bottle_id: row.id, //漂流瓶ID
    };

    //显示对话框
    this.packerDialogVisible = true;

    //获取数据
    this.getPackerList();
  }

  //处理创建
  private handleDelete(row: any): void {
    //显示提示
    MessageBox.confirm("删除操作不可恢复，确定删除吗？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(async () => {
        //处理删除操作
        await removeBottle({ id: row.id });

        //显示提示
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
          duration: 2000,
        });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //-------------------- 内容对话框 --------------------
  //定义变量
  private detail: any = {}; //内容
  private detailDialogVisible: boolean = false; //显示对话框

  //-------------------- 消息对话框 --------------------
  //定义变量
  private chatList: any[] = [];
  private chatTotal: number = 0;
  private chatDialogLoading: boolean = false;
  private chatDialogVisible: boolean = false; //显示对话框

  //请求数据
  private chatQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "", //用户ID

    //请求数据
    user_id: 0, //用户ID
    bottle_id: 0, //漂流瓶ID
  };

  //获取消息数据
  private async getChatList() {
    //显示加载
    this.chatDialogLoading = true;

    //数据赋值
    this.chatQuery.user_id = Number(this.chatQuery.user_id_str);

    //获取记录
    const { data } = await bottleChatList(this.chatQuery);

    //数据赋值
    this.chatList = data.list;
    this.chatTotal = data.total;

    //隐藏加载
    setTimeout(() => {
      this.chatDialogLoading = false;
    }, 0.5 * 1000);
  }

  //确定按钮
  private handleChatFilter(): void {
    //数据赋值
    this.chatQuery.page = 1;

    //获取数据
    this.getChatList();
  }

  //-------------------- 消息对话框 --------------------
  //定义变量
  private packerList: any[] = [];
  private packerTotal: number = 0;
  private packerDialogLoading: boolean = false;
  private packerDialogVisible: boolean = false; //显示对话框

  //请求数据
  private packerQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "", //用户ID

    //请求数据
    user_id: 0, //用户ID
    bottle_id: 0, //漂流瓶ID
  };

  //获取消息数据
  private async getPackerList() {
    //显示加载
    this.packerDialogLoading = true;

    //数据赋值
    this.packerQuery.user_id = Number(this.packerQuery.user_id_str);

    //获取记录
    const { data } = await bottlePickList(this.packerQuery);

    //数据赋值
    this.packerList = data.list;
    this.packerTotal = data.total;

    //隐藏加载
    setTimeout(() => {
      this.packerDialogLoading = false;
    }, 0.5 * 1000);
  }

  //确定按钮
  private handlePackerFilter(): void {
    //数据赋值
    this.packerQuery.page = 1;

    //获取数据
    this.getPackerList();
  }
}

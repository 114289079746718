//公共库
import "nprogress/nprogress.css";
import NProgress from "nprogress";
import { Route } from "vue-router";
import { Message } from "element-ui";

//本地引入
import router from "./router";
import settings from "./settings";
import { UserModule } from "@/store/modules/user";
import { PermissionModule } from "./store/modules/permission";

//获取标题
const getPageTitle = (title: string) => {
  //获取标签
  if (title) {
    return `${title} - ${settings.title}`;
  }
  return `${settings.title}`;
};

//初始化加载
NProgress.configure({ showSpinner: false });

//白名单
const whiteList = ["/login", "/auth-redirect"];

//切换后
router.afterEach((to: Route) => {
  //加载完成
  NProgress.done();

  //设置标题
  document.title = getPageTitle(to.meta?.title);
});

//切换前
router.beforeEach(async (to: Route, _: Route, next: any) => {
  //加载开始
  NProgress.start();

  //Token存在
  if (UserModule.token) {
    //登录界面
    if (to.path === "/login") {
      //显示主页
      next({ path: "/" });

      //记载完成
      NProgress.done();
    }
    //其他页面
    else {
      //账号登录
      if (UserModule.roles.length === 0) {
        try {
          //获取权限列表
          await UserModule.getPermissionMenu();

          //获取用户
          await UserModule.getUserInfo();

          //创建路由
          PermissionModule.generateRoutes();

          //获取动态路由
          PermissionModule.dynamicRoutes.forEach((route: any) => {
            router.addRoute(route);
          });

          //显示界面
          next({ ...to, replace: true });
        } catch (err) {
          //清空Token
          UserModule.resetToken();

          //显示错误消息
          Message.error(err ? JSON.stringify(err) : "Has Error");

          //显示登录
          next(`/login?redirect=${to.path}`);

          //加载完成
          NProgress.done();
        }
      }
      //直接显示
      else {
        next();
      }
    }
  }
  //Token不存在
  else {
    //白名单
    if (whiteList.indexOf(to.path) !== -1) {
      //直接显示
      next();
    }
    //其他页面
    else {
      //显示登录
      next(`/login?redirect=${to.path}`);

      //加载完成
      NProgress.done();
    }
  }
});

/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'zhuangban': {
    width: 800,
    height: 800,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M131.363 213.498a29.54 29.54 0 01-42.748-26.421v-49.231H29.538V512h196.923V165.949l-95.098 47.549zM423.385 137.846v49.231a29.535 29.535 0 01-42.748 26.419l-95.098-47.547V512h196.923V137.846h-59.077zm-29.539 196.923h-19.692c-16.313 0-29.539-13.225-29.539-29.538s13.225-29.538 29.539-29.538h19.692c16.313 0 29.539 13.225 29.539 29.538s-13.226 29.538-29.539 29.538zM305.595 108.308l-1.03 1.104 59.743 29.87V45.401zM206.405 108.308l-58.713-62.907v93.881l59.743-29.87zM325.782 0H186.13l69.86 74.852.01.01.01-.01L325.87 0z"/>'
  }
})


//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { bottlePickList } from "@/api/request/bottle";

//组件
@Component({
  name: "BottleList",
  components: {
    Pagination,
  },
})

//组件函数
export default class extends Vue {
  //定义变量
  private packerList: any[] = [];
  private packerTotal: number = 0;
  private packerDialogLoading: boolean = false;
  private packerDialogVisible: boolean = false; //显示对话框

  //创建
  created() {
    //获取数据
    this.getPackerList();
  }

  //请求数据
  private packerQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    user_id_str: "", //用户ID
    bottle_id_str: "", //漂流瓶ID

    //请求数据
    user_id: 0, //用户ID
    bottle_id: 0, //漂流瓶ID
  };

  //获取消息数据
  private async getPackerList() {
    //显示加载
    this.packerDialogLoading = true;

    //数据赋值
    this.packerQuery.user_id = Number(this.packerQuery.user_id_str);
    this.packerQuery.bottle_id = Number(this.packerQuery.bottle_id_str);

    //获取记录
    const { data } = await bottlePickList(this.packerQuery);

    //数据赋值
    this.packerList = data.list;
    this.packerTotal = data.total;

    //隐藏加载
    setTimeout(() => {
      this.packerDialogLoading = false;
    }, 0.5 * 1000);
  }

  //确定按钮
  private handlePackerFilter(): void {
    //数据赋值
    this.packerQuery.page = 1;

    //获取数据
    this.getPackerList();
  }
}

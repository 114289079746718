import axios from "axios";

//本地导入
import request, { getAppBaseApi, getHeaders } from "../request";

//用户在线列表
export const getUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/list",
  });

//强制下线
export const userLogout = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/logout",
  });

//在线人数
export const getUserOnlinenum = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/onlinenum",
  });

//分钟统计
export const getUserOnlineCountMinute = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/online/count/minute",
  });

//获取在线用户
export const getUserOnline = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/online",
  });

//导出在线用户
export const exportUserOnline = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/online`,
  });

//获取接通统计
export const getBloggerConnect = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/blogger/connect",
  });

//导出接通统计
export const exportBloggerconnect = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/blogger/connect`,
  });

//获取留存列表
export const getUserRegRemain = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/reg/remain",
  });

//导出留存列表
export const exportUserRegRemain = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/reg/remain`,
  });

//获取留存用户
export const getUserRegRemainUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/reg/remain/user_list",
  });

//导出留存用户数据
export const exportUserRegRemainUserList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/reg/remain/user_list`,
  });

//获取钻石消耗统计
export const getUserDiamondList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/diamond/list",
  });

//导出钻石消耗统计
export const exportUserDiamondList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/diamond/export`,
  });

//获取支付报表
export const getRechargeList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/recharge/list",
  });

//导出支付报表
export const exportRechargeList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/recharge/export`,
  });

//获取活跃用户
export const getUserActive = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/active",
  });

//导出活跃用户
export const exportUserActive = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/active`,
  });

//获取活跃用户数据
export const getActiveUserList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/active/user_list",
  });

//导出活跃用户数据
export const exportActiveUserList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/active/user_list`,
  });

//获取广场查询
export const getBloggerSquare = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/blogger/square",
  });

//获取新手优惠记录
export const getNewbieList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/user/newbie/list",
  });

//导出获取新手优惠记录
export const exportNewbieList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/user/newbie/export`,
  });

//获取用户活跃时长列表
export const getActiveUserDataList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/active/user_data_list",
  });

//导出用户活跃时长列表
export const exportActiveDataUser = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/active/user_data_list`,
  });

//获取App界面停留统计
export const getUserScene = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/scene",
  });

//导出App界面停留统计
export const exportUserScene = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/scene`,
  });

//获取首页博主泡泡记录埋点图表列表
export const getBloggerLogChart = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/bubbles_log/chart",
  });

//获取内各时间段博主泡泡记录列表
export const getBloggerLogHourList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/blogger/bubbles_log/hour/list",
  });

//获取用户月卡记录
export const getMonthCardRecordList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/month_card/record/list",
  });

//导出用户月卡记录
export const exportMonthCardRecordList = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/month_card/record/export`,
  });

//获取在线用户
export const getNewUserOnline = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/data/user/online/duration/list",
  });

//导出在线用户
export const exportNewUserOnline = (data: any) =>
  axios({
    data,
    method: "post",
    responseType: "blob",
    headers: getHeaders(),
    url: `${getAppBaseApi()}/adm/data/user/online/duration/export`,
  });

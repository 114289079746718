//本地导入
import request from "../request";

//漂流瓶列表
export const bottleList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/wish/bottle/list",
  });

//漂流瓶删除
export const removeBottle = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/wish/bottle/remove",
  });

//漂流瓶聊天列表
export const bottleChatList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/wish/bottle/chat/list",
  });

//漂流瓶拾取列表
export const bottlePickList = (data: any) =>
  request({
    data,
    method: "post",
    url: "/adm/wish/bottle/pick/list",
  });
